<template>
  <div>
    <el-card class="box-card" shadow="hover" v-for="item in articlelist">
      <el-row class="elrow">
        <el-col :span="24" style="text-align: left;margin-left: 20px; margin-top:5px;margin-bottom: 5px;">
          <div><el-tag type="danger" style="margin-right: 5px">{{item.Blogtype}}</el-tag><el-link @click="ontittle(item.Id)">{{item.Tittle}}</el-link></div>
        </el-col>
      </el-row>
      <div class="content">{{item.Tittlcontent}}</div>
      <el-divider></el-divider>
      <div class="message">发布时间：{{item.Createdate.replace('T',' ')}}</div>
      <div class="message">浏览：{{item.Browsecount}}</div>
      <div class="message">文章标签：<a :href="'/tag?val='+tag.Id" style="color: #1989fa;margin-left: 10px" v-for="tag in (reverseMessage(item.Id)) ">{{tag.Tagname}}</a> </div>
      <div class="message">文章分类：<a :href="'/classify?val='+classify.Id" style="color: #1989fa;margin-left: 10px" v-for="classify in (reverseMessageClassify(item.Id)) ">{{classify.Classifyname}}</a></div>
    </el-card>
    <el-pagination
        hide-on-single-page
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="astotalcount">
    </el-pagination>
  </div>
</template>

<script>
import {GetBlogClasifyAllList, GetBlogSearchList, GetBlogTagsAllList} from "@/network/blog";

export default {
  name: "Search",
  data(){
    return{
      page:1,//页码
      pagesize:10,//每页数量
      astotalcount:0,//总条数
      articlelist:[],
      taglist:[],
      classifylist:[],
    }
  },
  created() {
    //文章列表
    this.initdata(this.page,this.pagesize)
    //初始化tag
    this.inittag()
    //初始化分类
    this.initclassify()
  },
  methods:{
    //页码点击
    handleSizeChange(value){
      this.page =value
      this.initdata(this.page,this.pagesize)
    },
    //下一页，上一页点击
    handleCurrentChange(value){
      this.page =value
      this.initdata(this.page,this.pagesize)
    },
    //文章列表
    initdata(page,pagesize){
      GetBlogSearchList(page,pagesize,this.$route.query.val).then(res => {
        this.articlelist =res.data
        this.astotalcount =res.pagecount
      }).catch(err => {
        console.log(err);
      })
    },

    //初始化tag
    inittag(){
      GetBlogTagsAllList().then(res => {
        this.taglist =res.data
      }).catch(err => {
        console.log(err);
      })
    },

    //初始化分类
    initclassify(){
      GetBlogClasifyAllList().then(res => {
        this.classifylist =res.data
      }).catch(err => {
        console.log(err);
      })
    },

    // 获取文章tags
    reverseMessage(id) {
      let tag = []
      for (let item of this.taglist){
        if (item.Blogid===id){
          tag.push(item)
        }
      }
      return tag
    },
    // 获取文章分类
    reverseMessageClassify(id) {
      let tag = []
      for (let item of this.classifylist){
        if (item.Blogid===id){
          tag.push(item)
        }
      }
      return tag
    },
    ontittle(id){
      this.$router.push({path: '/article', query: {val: id}})
    },
  }
}
</script>

<style scoped>
.el-divider{
  margin: 10px 5px;
}
.el-link{
  font-size: 18px;
  color: #1989fa;
}
.content{
  text-align: left;
  margin-left: 20px;
  margin-top:5px;
  margin-bottom: 5px;
  color: #222222;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 20px;
}
.message{
  text-align: left;
  margin-left: 20px;
  font-size: 14px;
  float: left;
}
.box-card{
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.el-pagination{
  text-align: left;
}
</style>